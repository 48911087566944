import React, { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Header from "./Header";
import PropositionDisplay from "./PropositionDisplay";
import Footer from "./Footer";
import "./App.css";

function App() {
  const [selectedLanguage, setSelectedLanguage] = useState("pt");
  const [adultMode, setAdultMode] = useState(true);
  const [backendStarted, setBackendStarted] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState(false);

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  const startBackend = async () => {
    try {
      await fetch(`https://daring-acumen-397617.ew.r.appspot.com/api/start`, {
        method: "GET",
      });
      setBackendStarted(true);
    } catch (error) {
      console.error("Error starting backend:", error);
    }
  };

  const handleLogin = async () => {
    try {
      const response = await fetch(`https://daring-acumen-397617.ew.r.appspot.com/api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: password,
      });

      if (response.ok) {
        const data = await response.text();
        const isLoggedIn = parseInt(data, 10) === 1;
        setLoggedIn(isLoggedIn);
        if (!isLoggedIn) {
          setLoginError(true);
        }
      } else {
        setLoginError(true);
      }
    } catch (error) {
      console.error('Error logging in:', error);
      setLoginError(true);
    }
  };

  useEffect(() => {
    startBackend();
  }, []);

  return (
    <div className="app-container">
      <Header
        adultMode={adultMode}
        handleAdultModeChange={() => setAdultMode(!adultMode)}
        selectedLanguage={selectedLanguage}
        handleLanguageChange={handleLanguageChange}
      />
      {!loggedIn && (
      <div className="login-container">
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={loginError}
          helperText={loginError ? "Incorrect password" : ""}
        />
        <br />
        <Button className="login-button" variant="contained" onClick={handleLogin}>Login</Button>
      </div>
      )}
      {loggedIn && (
      <PropositionDisplay
        classname="proposition-display"
        selectedLanguage={selectedLanguage}
        adultMode={adultMode}
      />
      )}
      {backendStarted ? (
        <div className="developed-by">
          <span>Developed by </span>
          <a
            href="https://www.linkedin.com/in/joaocbarros/"
            className="developer-link"
          >
            João Barros
          </a>
        </div>
      ) : (
        <CircularProgress className="developed-by" size={24} color="inherit" />
      )}
      <Footer className="Footer" />
    </div>
  );
}

export default App;
