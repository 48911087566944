import React from 'react';
import PropTypes from 'prop-types';
import {
  US, PT, FR, DE, ES,
} from 'country-flag-icons/react/3x2';
import {
  Container,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
} from '@mui/material';
import { ReactComponent as Lamp } from './resources/images/lamp.svg';
import './Header.css';

function Header({
  adultMode,
  handleAdultModeChange,
  selectedLanguage,
  handleLanguageChange,
}) {
  const languages = [
    {
      code: 'en',
      name: 'US',
      flag: <US title="US" />,
    },
    {
      code: 'pt',
      name: 'PT',
      flag: <PT title="PT" />,
    },
    {
      code: 'fr',
      name: 'FR',
      flag: <FR title="FR" />,
    },
    {
      code: 'de',
      name: 'DE',
      flag: <DE title="DE" />,
    },
    {
      code: 'es',
      name: 'ES',
      flag: <ES title="ES" />,
    },
  ];

  return (
    <header className="app-header">
      <Container>
        <div className="top-right">
          <FormControlLabel
            control={<Switch checked={adultMode} onChange={handleAdultModeChange} />}
            label="Adult Mode"
          />
          <Select value={selectedLanguage} onChange={handleLanguageChange} className="custom-select">
            {languages.map((lang) => (
              <MenuItem key={lang.code} value={lang.code}>
                <span className="flag-icon">{lang.flag}</span>
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="header-title">
          <p>INTELLECTUALLY DISHONEST</p>
          <Lamp className="lamp-image" />
        </div>
      </Container>
    </header>
  );
}

Header.propTypes = {
  adultMode: PropTypes.bool.isRequired,
  handleAdultModeChange: PropTypes.func.isRequired,
  selectedLanguage: PropTypes.string.isRequired,
  handleLanguageChange: PropTypes.func.isRequired,
};

export default Header;
