import React from "react";
import "./Footer.css";
import { ReactComponent as Lamp } from "./resources/images/lamp.svg";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-title">
          <a href="https://www.instagram.com/despolariza/">DESPOLARIZA</a>
          <Lamp className="lamp-image-small" />
          <span className="ampersand"> &</span>
          <a href="https://www.instagram.com/jj.bitters/">JJ Bitters</a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
