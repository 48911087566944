import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Typography, Button, CircularProgress } from "@mui/material";
import "./PropositionDisplay.css";

function PropositionDisplay({ selectedLanguage, adultMode }) {
  const [proposition, setProposition] = useState("");
  const [loading, setLoading] = useState(false);

  const languageToContent = useMemo(
    () => ({
      en: "Feel free to be Intellectually Dishonest",
      pt: "Fica tranquilo. Aqui podes ser Intelectualmente Desonesto",
      fr: "N'hésitez pas à être Intellectuellement Malhonnête",
      de: "Fühlen Sie sich frei, Intellektuell Unehrlich zu sein",
      es: "Siéntete libre de ser Intelectualmente Deshonesto",
    }),
    [],
  );

  const languageToContentButton = {
    en: "New Proposition",
    pt: "Nova Proposição",
    fr: "Nouvelle Proposition",
    de: "Neuer Vorschlag",
    es: "Nueva Proposición",
  };

  useEffect(() => {
    setProposition(languageToContent[selectedLanguage]);
  }, [languageToContent, selectedLanguage]);

  const fetchNewProposition = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://daring-acumen-397617.ew.r.appspot.com/api/proposition?languageCode=${selectedLanguage}&isAdult=${adultMode}`,
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.text();
      setProposition(data);
    } catch (error) {
      console.error("Error fetching new proposition:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="proposition-display-container">
      <Typography variant="h4" gutterBottom>
        {proposition}
      </Typography>
      <Button
        variant="outlined"
        onClick={fetchNewProposition}
        className="button-new-proposition"
        disabled={loading}
      >
        {loading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          languageToContentButton[selectedLanguage]
        )}
      </Button>
    </div>
  );
}

PropositionDisplay.propTypes = {
  selectedLanguage: PropTypes.string.isRequired,
  adultMode: PropTypes.bool.isRequired,
};

export default PropositionDisplay;
